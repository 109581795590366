import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { firebaseConnect, getVal } from "react-redux-firebase";
import { compose } from "redux";
import {
  PageBody,
  PageTitle,
  Container,
  CenterWrapper,
} from "app/components/RouteComponents";
import { Helmet } from "react-helmet-async";
import NextBadgeProgress from "app/components/NextBadgeProgress";
import BadgeList from "app/components/BadgeList";
import { routePadding } from "styles/variables";

const StyledNextBadgeProgress = styled(NextBadgeProgress)`
  ${({ theme }) => theme.mediaQueries.ltmd} {
    padding: 0 ${routePadding}px;
  }
`;

const Achievements = ({ achievements, progress, requested, userBadges }) => (
  <CenterWrapper>
    <Helmet title="Achievements | Online Dance Classes and Tutorials" />
    <Container>
      {!requested && <LoaderCentered />}
      {requested &&
        achievements &&
        _.map(achievements, achievement => {
          let amountToEarn = 0;
          let progressBarAmount = 0;
          if (achievement.slug === "classes_completed") {
            const [nextBadge] = _.differenceBy(
              achievement.badges,
              userBadges ? userBadges[achievement.slug] : {},
              "id"
            );
            const lastBadge = achievement.badges[achievement.badges.length - 1];
            progressBarAmount = progress.classes_completed;

            amountToEarn =
              // eslint-disable-next-line camelcase
              nextBadge?.amount_to_earn || lastBadge?.amount_to_earn;
          }
          return (
            <div key={achievement.slug}>
              <PageTitle>{achievement.name}</PageTitle>
              {amountToEarn > 0 && (
                <StyledNextBadgeProgress
                  progress={progressBarAmount}
                  amountToEarn={amountToEarn}
                />
              )}
              <PageBody>
                <BadgeList
                  achievementSlug={achievement.slug}
                  badges={achievement.badges}
                />
              </PageBody>
            </div>
          );
        })}
    </Container>
  </CenterWrapper>
);

const mapStateToProps = ({ firebase, user }) => ({
  achievements: getVal(firebase, "data/achievements"),
  requested: firebase.requested.achievements,
  userBadges: user.public.badges,
  progress: user.progress,
});

Achievements.defaultProps = {
  achievements: null,
  progress: 0,
  requested: false,
  userBadges: {},
};

Achievements.propTypes = {
  achievements: PropTypes.shape({}),
  progress: PropTypes.shape({}),
  requested: PropTypes.bool,
  userBadges: PropTypes.shape({}),
};

export default compose(
  connect(mapStateToProps),
  firebaseConnect(["achievements"])
)(Achievements);
