import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import BadgeCard from "../BadgeCard";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
`;

const BadgeCardWrapper = styled.div`
  margin: 0 20px 20px 0;
`;

const BadgeList = ({ badges, achievementSlug }) => (
  <Wrapper>
    {_.map(badges, badge => (
      <BadgeCardWrapper key={badge.id}>
        <BadgeCard badge={badge} achievementSlug={achievementSlug} />
      </BadgeCardWrapper>
    ))}
  </Wrapper>
);

BadgeList.propTypes = {
  achievementSlug: PropTypes.string.isRequired,
  badges: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default BadgeList;
