import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "react-moment";
import styled from "styled-components";
import { Check } from "app/components/Icon";
import PropTypes from "prop-types";
import env from "helpers/env";

const defaultProps = {
  userBadge: null,
};

const propTypes = {
  badge: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  userBadge: PropTypes.shape({}),
};

const Wrapper = styled.div`
  width: 160px;
  height: 100%;
  border-radius: 4px;
  border: 2px solid
    ${({ earned, theme }) => (earned ? theme.colors.limeGreen : "transparent")};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const CheckWrapper = styled.div`
  height: 30px;
  width: 30px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: linear-gradient(
    45deg,
    transparent 50%,
    ${({ theme }) => theme.colors.limeGreen} 51%
  );
`;

const CheckIcon = styled.div`
  color: ${({ theme }) => theme.colors.white};
  width: 10px;
  margin-right: 5px;
  margin-top: 2px;
`;

const Badge = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
`;

const Date = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.grey};
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: ${({ earned, theme }) =>
    earned ? theme.colors.black : theme.colors.grey};
`;

const BadgeCard = ({ badge, userBadge }) => (
  <Wrapper earned={!!userBadge}>
    {userBadge && (
      <CheckWrapper>
        <CheckIcon as={Check} />
      </CheckWrapper>
    )}
    {userBadge && (
      <Badge
        src={`${env("PUBLIC_ASSET_PATH")}/badges/${badge.image_name}.png`}
      />
    )}
    {!userBadge && (
      <Badge
        src={`${env("PUBLIC_ASSET_PATH")}/badges/${badge.image_name}-grey.png`}
      />
    )}
    <Title earned={!!userBadge}>{badge.name}</Title>
    {userBadge && (
      <Date>
        Achieved <Moment date={userBadge.earned_at} format="MMM D, YYYY" />
      </Date>
    )}
  </Wrapper>
);

const mapStateToProps = (state, props) => ({
  userBadge:
    state.user.public.badges &&
    _.find(state.user.public.badges[props.achievementSlug], [
      "id",
      props.badge.id,
    ]),
});

BadgeCard.defaultProps = defaultProps;
BadgeCard.propTypes = propTypes;

export default connect(mapStateToProps)(BadgeCard);
