import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import ProgressBar from "app/components/ProgressBar";

const Wrapper = styled.div`
  width: 100%;
  max-width: 300px;
`;

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;

const StyledProgressBar = styled(ProgressBar)`
  height: 5px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

const NextBadgeProgress = ({ amountToEarn, className, progress, theme }) => (
  <Wrapper className={className}>
    <Label>
      <div>Next Reward</div>
      <div>
        {progress}/{amountToEarn}
      </div>
    </Label>
    <StyledProgressBar
      color={theme.colors.limeGreen}
      percent={(progress / amountToEarn) * 100}
    />
  </Wrapper>
);

NextBadgeProgress.defaultProps = {
  amountToEarn: 0,
  className: null,
  progress: 0,
};

NextBadgeProgress.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  amountToEarn: PropTypes.number,
  className: PropTypes.string,
  progress: PropTypes.number,
};

export default withTheme(NextBadgeProgress);
